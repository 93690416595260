<template>
  <div class="home el-row">
    <el-col class="home-left" :span="18">
      <el-row class="left-top">
        <el-col :span="8" class="top-left">
          <!-- 资产盘点 -->
          <div class="block">
            <div class="title">
              <h1>资产盘点</h1>
              <p>property assessment</p>
            </div>
            <div id="assetsCheckChartId" class="chart-box"></div>
            <p class="assets-check-update-time">更新时间: {{statisticsData.updateTime && statisticsData.updateTime.split(' ')[0]}}</p>
          </div>
        </el-col>
        <el-col :span="16" class="top-right">
          <!-- 资产总况 -->
          <div class="block">
            <div class="title">
              <h1>资产总况</h1>
              <p>Asset summary</p>
            </div>
            <div class="assets-summary">
              <p>{{statisticsData.total}}<span class="unit">台</span></p>
              <ul class="statistics-box" id="statisticsBox">
                <li v-for="(item, idx) in statisticsData.typeItems" :key="idx">
                  <div class="assets-summary-chart-box" :id="'assetsSummaryEchartId' + idx"></div>
                  <span class="name">{{item.label}}</span>
                </li>
              </ul>
              <div class="update-time">更新时间: {{statisticsData.updateTime}}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="left-bottom">
        <el-col :span="24" class="bottom-all">
          <!-- TPM点检状态 -->
          <div class="block">
            <div class="title">
              <h1>TPM点检状态</h1>
              <p>TPM check status</p>
            </div>
            <div id="tmpChartId" class="chart-box"></div>
          </div>
        </el-col>
      </el-row>
    </el-col>
    <el-col class="home-right" :span="6">
      <div class="block">
        <div class="title">
          <h1>预警设备分布</h1>
          <p>Alarm equipment distribution</p>
        </div>
        <div class="distribution">
          <div id="distributionChartId" class="chart-box"></div>
        </div>
        <div class="title info">
          <h1>预警信息</h1>
          <p>Alarm information</p>
        </div>
        <div class="info">
          <template v-if="warnMsgList.length > 0">
            <ul class="info-list">
              <li v-for="item in warnMsgList" @click="handleMsgDetail(item)" :key="item.id">{{item.content}}</li>
            </ul>
            <div class="pagination-box">
              <el-pagination
                @current-change="handleCurrentPage"
                :current-page="pagination.currentPage"
                :page-size="pagination.pageSize"
                layout="prev, pager, next"
                :total="pagination.total">
              </el-pagination>
            </div>
          </template>
          <div v-else class="no-data">暂无数据</div>
        </div>
      </div>
    </el-col>

    <!-- 预警信息详情弹框 -->
    <el-dialog v-model="detailDialogVisible" width="700px" @close="detailDialogVisible = false">
      <template #title>
        <h1>详细信息</h1>
        <p>details</p>
      </template>
      <ul class="detail-list">
        <li>
          <label>工具名称</label>
          <span>{{curMsgDetail.name}}</span>
        </li>
        <li>
          <label>所属工段</label>
          <span>{{curMsgDetail.workStationName}}</span>
        </li>
        <li>
          <label>所属班组</label>
          <span>{{curMsgDetail.classGroupName}}</span>
        </li>
        <li>
          <label>所属岗/工位</label>
          <span>{{curMsgDetail.empStationName}}</span>
        </li>
        <li>
          <label>投入使用时间</label>
          <span>{{curMsgDetail.entryDate}}</span>
        </li>
        <li>
          <label>已拧紧次数</label>
          <span>{{curMsgDetail.usedTimes}}</span>
        </li>
        <li>
          <label>预警次数</label>
          <span class="warning">{{curMsgDetail.warnTimes}}</span>
        </li>
        <li>
          <label>预警开始时间</label>
          <span>{{curMsgDetail.warnStartTime || '工具已更换'}}</span>
        </li>
      </ul>
      <template #footer>
        <el-button @click="signHasBeenRead">已处理</el-button>
        <el-button @click="detailDialogVisible = false">关 闭</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { home } from '@/api'

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      statisticsData: {},
      colorList: [ // 资产总况颜色列表
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#7DA4F4'},{offset: 1, color: '#476BE5'}]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#8982FF'},{offset: 1, color: '#4B43DF'}]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#82F7FF'},{offset: 1, color: '#0BA3B5'}]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#C582FF'},{offset: 1, color: '#8C43DF'}]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#FFE382'},{offset: 1, color: '#CDAA21'}]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#7DF48F'},{offset: 1, color: '#47E54C'}]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#FFBC82'},{offset: 1, color: '#EB6209'}]),
      ],
      warnMsgList: [],
      pagination: { // 分页信息
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      detailDialogVisible: false, // 详情弹框visible
      curMsgDetail: {}, // 当前信息详情

      timer: null
    }
  },
  mounted() {
    this.loadTmpData()
    this.loadStatistics()
    this.loadWarnStatistics()
    this.loadWarnMsg()

    this.reloadHome()
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
  methods: {
    // 每分钟刷新首页
    reloadHome() {
      this.timer = setInterval(() => {
        this.loadTmpData()
        this.loadStatistics()
        this.loadWarnStatistics()
        this.loadWarnMsg()
      }, 60 * 1000)
    },
    // TPM点检状态
    async loadTmpData() {
      const {code, msg, data} = await home.getTPM()
      if(code === 0) {
        let xAxisD = [], seriesD_ok = [], seriesD_abnormal = []
        data.forEach(item => {
          xAxisD.push(item.date)
          seriesD_ok.push(item.ok)
          seriesD_abnormal.push(item.abnormal)
        })

        
        this.$nextTick(() => {
          let myChart = echarts.getInstanceByDom(document.getElementById('tmpChartId'))
          if(!myChart) {
            myChart = echarts.init(document.getElementById('tmpChartId'))
          }
          myChart.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              // formatter: '{b}：{c}'
            },
            legend: {
              data: ['正常数量', '异常数量'],
              itemHeight: 8,
              icon: "circle",
              textStyle: {
                color: '#fff'
              },
              right: 40,
              top: 20 
            },
            grid: {
              left: 24,
              right: 10,
              top: 70
            },
            xAxis: [{
              type: 'category',
              data: xAxisD
            }],
            yAxis: [{
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: 'rgba(151, 151, 151, 0.3)'
                }
              },
            }],
            color: ['#31B067', '#F9574F'],
            series: [
              {
                name: '正常数量',
                type: 'line',
                smooth: true,
                // lineStyle: {
                //   color: '#31B067'
                // },
                areaStyle: {
                  opacity: 0.5,
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {offset: 0, color: '#31B067' // 0% 处的颜色
                      }, 
                      {
                        offset: 1, color: '#fff' // 100% 处的颜色
                      }
                    ],
                  },
                },
                data: seriesD_ok
              },
              {
                name: '异常数量',
                type: 'line',
                smooth: true,
                // lineStyle: {
                //   color: '#F9574F'
                // },
                areaStyle: {
                  opacity: 0.5,
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {offset: 0, color: '#F9574F' // 0% 处的颜色
                      }, 
                      {
                        offset: 1, color: '#fff' // 100% 处的颜色
                      }
                    ],
                  },
                },
                data: seriesD_abnormal
              },
            ]
          })
        })
      } else {
        this.$message.error(msg)
      }
    },
    // 资产数据统计
    async loadStatistics() {
      const {code, msg, data = {}} = await home.getStatistics()
      if(code === 0) {
        this.statisticsData = data
        
        this.$nextTick(() => {
          let assetsCheckData = []
          if(data && Array.isArray(data.statusItems)) {
            assetsCheckData = data.statusItems.map(item => {
              return {
                value: item.number,
                name: item.label
              }
            })
          }

          let myChart = echarts.getInstanceByDom(document.getElementById('assetsCheckChartId'))
          if(!myChart) {
            myChart = echarts.init(document.getElementById('assetsCheckChartId'))
          }
          myChart.setOption({
            // tooltip: {
            //   trigger: 'item',
            //   alwaysShowContent: true,
            // },
            legend: {
              itemHeight: 8,
              icon: "circle",
              textStyle: {
                color: '#fff'
              },
              orient: 'vertical',
              right: 0,
              top: 54
            },
            series: [{
              type: 'pie',
              center: ['42%', '55%'],
              radius: ['48%', '76%'],
              color: ['#4973FF', '#F9574F', '#FFE755', '#91cc75', '#73c0de', '#9a60b4', '#ea7ccc', '#3ba272'],
              labelLine: {
                show: false
              },
              label: {
                show: false,
                position: 'center',
                formatter: params => {
                  return '{per| ' + params.percent + '%}\n{name|' + params.name + '}' 
                },
                rich: {
                  per: {
                    fontSize: '30px',
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                    color: '#fff',
                  },
                  name: {
                    fontSize: '14px',
                    color: '#B4B4C5',
                    padding: [10, 0, 0, 0]
                  }
                }
              },
              emphasis: {
                label: {
                  show: true,
                }
              },
              // data: [
              //   {value: data.total.ok, name: '正常数'},
              //   {value: data.total.loss, name: '遗失数'},
              //   {value: data.total.fault, name: '故障数'},
              // ]
              data: assetsCheckData
            }]
          })
          myChart.dispatchAction({ type: 'highlight', dataIndex: 0}) // 默认高亮
          myChart.on('mouseover', (e) => {
            if (e.dataIndex !== 0) { // 当鼠标移除的时候 使默认的索引值去除默认选中
              myChart.dispatchAction({ type: 'downplay', dataIndex: 0 })
            }
          })
        })


        this.$nextTick(() => {
          let total = 0
          data.typeItems.forEach(item => total += Math.floor(item.number))
          console.log('total', total)

          data.typeItems.forEach((item, idx) => {
            let okVal = item.number
            let notOk = okVal ? total - item.number : 0
            // let perVal = okVal ? Math.floor(okVal / total * 100) : 0

            let boxW = document.getElementById('statisticsBox')
            let charDom = document.getElementById('assetsSummaryEchartId' + idx)
            charDom.style.width = Math.floor(boxW.offsetWidth/7)+ 'px'

            let myChart = echarts.getInstanceByDom(charDom)
            if(!myChart) {
              myChart = echarts.init(document.getElementById('assetsSummaryEchartId' + idx))
            }

            myChart.setOption({
              series: [{
                type: 'pie',
                center: ['50%', '40%'],
                radius: ['40%', '72%'],
                color: [okVal ? this.colorList[idx] : '#2c2c42', '#2c2c42'],
                labelLine: {
                  show: false
                },
                label: {
                  show: true,
                  position: 'center',
                  fontSize: '10px',
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                  color: '#B4B4C5',
                  // formatter: `${okVal}${perVal}%`
                  formatter: `${okVal}`
                },
                data: [
                  {value: okVal, name: '正常'},
                  {value: notOk, name: '非正常'},
                ]
              }]
            })
          })
        })
        
      } else {
        this.$message.error(msg)
      }
    },
    // 预警统计
    async loadWarnStatistics() {
      // 获取5天的预警数据展示
      // let params = {days: 6}
      const {code, msg, data = {}} = await home.getWarnStatistics()
      if(code === 0) {
        let xAxisD = [], seriesD = []
        data.forEach(item => {
          xAxisD.push(item.date)
          seriesD.push(item.count)
        })

        this.$nextTick(() => {
          let myChart = echarts.getInstanceByDom(document.getElementById('distributionChartId'))
            if(!myChart) {
              myChart = echarts.init(document.getElementById('distributionChartId'))
            }
            myChart.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              // formatter: '{b}：{c}',
              // alwaysShowContent: true,
            },
            grid: {
              left: 24,
              right: 10,
              top: 70
            },
            xAxis: [{
              type: 'category',
              data: xAxisD
            }],
            yAxis: [{
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: 'rgba(151, 151, 151, 0.3)'
                }
              },
            }],
            color: ['#F9574F'],
            series: [{
              name: '预警设备数量',
              type: 'line',
              smooth: true,
              areaStyle: {
                opacity: 0.5,
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {offset: 0, color: '#F9574F' // 0% 处的颜色
                    }, 
                    {
                      offset: 1, color: '#fff' // 100% 处的颜色
                    }
                  ],
                },
              },
              data: seriesD
            }]
          })
        })
      } else {
        this.$message.error(msg)
      }
    },
    // 预警消息
    async loadWarnMsg() {
      let params = {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }
      const {code, msg, data, total} = await home.getWarnMsg(params)
      if(code === 0) {
        this.warnMsgList = data || []
        this.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 预警消息-改变当前页
    handleCurrentPage(currentPageNo) {
      this.pagination.currentPage = currentPageNo
      this.loadWarnMsg()
    },
    // 查看预警信息详情
    async handleMsgDetail({id, bizId}) {
      const {code, msg, data = {}} = await home.getWarnMsgDetail(bizId)
      if(code === 0) {
        this.curMsgDetail = {
          ...data, 
          id
        }
        this.detailDialogVisible = true
      } else {
        this.$message.error(msg)
      }
    },
    // 标记为已处理
    async signHasBeenRead() {
      let params = {
        id: this.curMsgDetail.id
      } 

      const {code, msg} = await home.signHasBeenRead(params)
      if(code === 0) {
        this.$message.success('操作成功')
        this.loadWarnMsg()
        this.detailDialogVisible = false
      } else {
        this.$message.error(msg)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  $s_padding: 8px;

  height: 100%;
  .block {
    height: 100%;
    background: linear-gradient(180deg, #2E2E44 0%, #1B1B27 100%);
    box-shadow: 0px 11px 14px 0px rgba(9, 9, 13, 0.65);
    border-radius: 16px;
    padding: 20px 2vw;
    position: relative;
    .title {
      position: absolute;
      top: 20px;
      left: 2vw;
      &.info {
        position: static;
      }
      h1 {
        font-size: 16px;
        font-weight: normal;
      }
      p{
        font-size: 12px;
        color: #B4B4C5;
        margin-top: 4px;
      }
    }
    .chart-box {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }
    .assets-check-update-time {
      position: absolute;
      bottom: 50px;
      font-size: 12px;
      margin-left: 2.5vw;
      color: #B4B4C5;
    }
    .assets-summary {
      & > p {
        font-size: 66px;
        color: #FFFFFF;
        margin-top: 100px;
        border-bottom: 1px solid rgba($color: #979797, $alpha: .3);
        font-style: italic;
        .unit {
          color: #B4B4C5;
          font-size: 20px;
          margin-left: 6px;
        }
      }
      .statistics-box {
        height: 160px;
        border-bottom: 1px solid rgba($color: #979797, $alpha: .3);
        list-style: none;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        & > li {
          position: relative;
          height: 100%;
          .assets-summary-chart-box {
            position: relative;
            height: 100%;
          }
          .name {
            color: #B4B4C5;
            font-size: 12px;
            @media screen and (min-width: 1500px){
              font-size: 14px;
            }
            position: absolute;
            width: 100%;
            text-align: center;
            bottom: 20px;
          }
        }
      }
      .update-time {
        font-size: 12px;
        margin-top: 16px;
        color: #B4B4C5;
      }
    }
    .distribution {
      height: 250px;
    }
    .info-list {
      list-style: none;
      margin-top: 20px;
      & > li {
        height: 36px;
        line-height: 36px;
        background: #222232;
        border-radius: 8px;
        color: #FA3536;
        font-size: 14px;
        padding: 0 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }
  .home-left {
    padding-right: $s_padding;
    .left-top {
      height: 450px;
      .top-left {
        padding-right: $s_padding;
      }
      .top-right {
        padding-left: $s_padding;
      }
    }
    .left-bottom {
      height: 450px;
      padding-top: 20px;
    }
  }
  .home-right {
    padding-left: $s_padding;
  }

  .detail-list {
    list-style: none;
    & > li {
      border-bottom: 1px solid rgba($color: #979797, $alpha: 0.3);
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 32px;
      & > label {
        font-size: 14px;
        color: #B4B4C5;
      }
      & > span {
        font-size: 16px;
        color: #fff;
        &.warning {
          color: #FA3536;
        }
      }
    }
  }
}
</style>